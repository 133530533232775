<script lang="ts" setup>
import { FormKitLazyProvider } from '@formkit/vue'
import { useElementHover } from "@vueuse/core"
import type { InputTextProps } from "./InputText.props"
import { ValidationVisiblityEnum } from "~/types/validation"
import { debounce } from "lodash"
import type { ITransformRule } from "~/utils/input/transformer/transformer.model"
import {
  ERuleName,
  ETrasformType
} from "~/utils/input/transformers/transformers.model"
import { transformers } from "~/utils/input/transformers/transformers"

const props = withDefaults(defineProps<InputTextProps>(), {
  type: "text",
  placeholder: "",
  disabled: false,
  helpText: "",
  validation: "",
  forceError: false,
  showRequiredness: false,
  showValidationMessagesAlways: false,
  validationMessages: undefined,
  isDropdown: false,
  isCalendarSelector: false,
  hasFixedWidth: false,
  showAsterisk: false,
  validationVisibility: ValidationVisiblityEnum.Blur,
  debounceTime: 0,
  forceEmitBeforeDebounce: false,
  transformRules: []
})

const emit =
  defineEmits<{
    (e: "update:modelValue", value: string): string
    (e: "onUpdateValidity", value: boolean): void
    (e: "onUpdateDirty", value: boolean): void
    (e: "onFocusIn"): void
    (e: "onFocusOut"): void
    (e: "onEdit"): void
  }>()

const inputField = ref("")
const inputType = ref(props.type)
const inputElement = ref()
const container = ref()
const isInputDisabled = ref(props.disabled)

//AUTOFILL HANDLERS
const isAutocompleted = ref(false)

onMounted(() => {
  inputElement.value = container.value?.querySelector("input")
  if (inputElement.value) {
    inputElement.value.addEventListener("animationstart", () => {
      isAutocompleted.value = true
    })
  }
})

onUnmounted(() => {
  inputElement.value = container.value?.querySelector("input")
  if (inputElement.value) {
    inputElement.value.removeEventListener("animationstart", () => {
      isAutocompleted.value = true
    })
  }
})

//USER AGENT SNIFFING
const isFirefox = ref(false)

onMounted(() => {
  const userAgent = navigator.userAgent.toLowerCase()
  isFirefox.value = userAgent.includes("firefox")
})

const applyTransformationRule = (
  value: string | number,
  rule: ITransformRule
) => {
  switch (rule.type) {
    case ETrasformType.replace:
      return transformers.replaceStringTransformer(
        value,
        rule.match,
        rule.replacer
      )
    case ETrasformType.toString:
      return value.toString()
    default:
      return value
  }
}

const trasformEmittableValue = (
  value: string | number,
  transformRules: ITransformRule[]
) =>
  transformRules.reduce(
    (transformedValue, rule) => applyTransformationRule(transformedValue, rule),
    value
  )

const setDefaultTransformRules = (): ITransformRule[] =>
  transformers.getTransformesRules([ERuleName.toString])

const emitModel = (value: string | number) => {
  const rules = setDefaultTransformRules().concat(props.transformRules)
  const emittableValue = trasformEmittableValue(value, rules)
  typeof emittableValue === "string" &&
    emit("update:modelValue", emittableValue)
}

const debounceFunction = debounce((value) => {
  emitModel(value)
}, props.debounceTime)

//MODEL VALUE
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    if (!value) {
      value = ""
    }
    //Emit full value before debounce
    if (props.forceEmitBeforeDebounce) {
      emit("update:modelValue", value.toString())
    }
    debounceFunction(value)
  }
})

//VALIDITY HANDLERS
const isValid = computed(() => {
  if (props.forceError) {
    return false
  }

  if (inputField.value) {
    const node = inputField.value.node
    if (node.context.state.dirty && node.context.state.rules) {
      return node.context.state.complete
    }
  }
  return true
})

watch(isValid, (newValue) => {
  emit("onUpdateValidity", newValue)
})

//DIRTY HANDLERS
const isDirty = computed(() => {
  if (inputField.value?.node) {
    const node = inputField.value.node
    return node.context.state.dirty
  }
  return false
})

watch(isDirty, (newValue) => {
  emit("onUpdateDirty", newValue)
})

//FOCUS HANDLERS
const isFocused = ref(false)

const handleFocusIn = () => {
  if (isFocused.value) return
  isFocused.value = true
  emit("onFocusIn")
}

const handleFocusOut = () => {
  if (!isFocused.value) return
  isFocused.value = false
  emit("onFocusOut")
}

const focusInput = () => {
  const myElement = document.getElementById(props.name)
  if (myElement) {
    myElement.focus()
    emit("onFocusIn")
  }
}

const labelFocusBehaviour = computed(() => {
  if (props.inlineLabel) {
    // with an inlineLabel, the animation never displays
    return false
  } else if (props.forceFocus) {
    return true
  } else if (props.isDropdown) {
    // if this is input is dropdown, the label should rise only when there is a value, and not on focus
    return !!value.value || !!isAutocompleted.value
  } else {
    // regular behaviour: the label rises if the input is focused or contains a value (including autocomplete)
    return !!value.value || isFocused.value || isAutocompleted.value
  }
})

const labelClass = computed(() => {
  return {
    "floating-label__textfield !top-0 -translate-y-1/2 bg-grey-main px-1 ":
      labelFocusBehaviour.value
  }
})

//VALIDATION MESSAGES
const showValidationMessages = props.showValidationMessagesAlways
  ? !!props.validationMessages
  : props.type === "password" || props.type === "email"

//REQUIREDNESS HANDLER
const isRequired = computed(() => {
  return (
    props.isRequired ||
    (props.validation &&
      typeof props.validation === "string" &&
      props.validation.includes("required")) ||
    (Array.isArray(props.validation) &&
      props.validation.some((i) => i.includes("required")))
  )
})

//MAX LENGTH HANDLERS
const maxChar = computed(() => {
  if (props.validation && props.validation.toString().includes("length:")) {
    const lengthVal = props.validation
      .toString()
      .match(/length:\d{1,3}\,\d{1,3}/)

    return lengthVal?.length ? lengthVal[0].split(",")[1] : 0
  }
  return 0
})

const currentLength = computed(() => {
  return typeof value.value == "string" ? value.value?.length : 0
})

const countChars = computed(() => {
  return `${currentLength.value}/${maxChar.value} `
})

//PASSWORD ICON & VISIBILITY HANDLERS
const showPassword = computed(() => (props.type === "password" ? true : false))

const isPasswordHidden = ref(false)
const suffixIconsNotAbsolute = props.type == "password" || props.type == "email"

const togglePassword = () => {
  inputType.value = isPasswordHidden.value ? "password" : "text"
  isPasswordHidden.value = !isPasswordHidden.value
}

//EMAIL HANDLERS
const handleEdit = () => {
  emit("onEdit")
}

//TOOLTIP HANDLERS
const questionMark = ref()
const isQuestionMarkOnHover = ref(
  process.server ? false : useElementHover(questionMark)
)

const isTooltipVisible = ref(false)
watch(isQuestionMarkOnHover, (newValue) => {
  newValue ? (isTooltipVisible.value = true) : (isTooltipVisible.value = false)
})
</script>

<template>
  <div ref="container" class="input-text-component">
    
<FormKitLazyProvider config-file="true">
<FormKit
      ref="inputField"
      :id="id ?? name"
      :name="name"
      v-model="value"
      :type="inputType"
      :autocomplete="autocomplete"
      :placeholder="isFirefox ? '' : label"
      :validation="validation"
      :disabled="isInputDisabled"
      :validation-rules="validationRules"
      :validation-messages="validationMessages"
      :validation-visibility="props?.validationVisibility"
      :readonly="isReadonly"
      inner-class="h-10"
      :classes="{
        outer: {
          relative: true,
          'pt-[6px]': !!label || !!customDropdownLabel
        },
        wrapper: 'relative bg-inherit',
        inner: {
          //!NOTE: with suffixIconsNotAbsolute == true, theformkit-input border is disabled, so all the border properties have to be transferred to formkit-inner
          'flex flex-row border !outline-none rounded border-black-10 hover:border-slate-800 focus:border-green-70':
            suffixIconsNotAbsolute,
          'border-negative-main': suffixIconsNotAbsolute && !isValid,
          'border-grey-main bg-grey-main': disabled
        },
        input: {
          'disabled:text-black-40 py-2 pl-4 pr-3 w-full input-text__textfield placeholder:sr-only bg-white !outline-none': true,
          'error-border__textfield': !isValid && !suffixIconsNotAbsolute,
          'caret-transparent cursor-pointer pr-4 text-font-bold': isDropdown,
          'self-center justify-self-start rounded !ring-0  !border-0 !border-none !hover:border-none !focus:border-none !disabled:border-none ![dirty]:border-none !focus-visible:outline-none':
            suffixIconsNotAbsolute,
          '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none':
            type === 'number'
        },
        message: 'text-negative-main mouse mt-[3px]',
        messages: {
          hidden: !showValidationMessages || !validationMessages,
          inline:
            showValidationMessages &&
            !!validationMessages &&
            (!helpText || isHelpTextHidden || isValid),
          'block pt-5':
            showValidationMessages &&
            !!validationMessages &&
            !!helpText &&
            !isHelpTextHidden &&
            !isValid
        }
      }"
      @focusin="handleFocusIn"
      @focusout="handleFocusOut"
      class="text_fields-input_text"
    >
      <template v-if="maskaFormat" #input>
        <input
          :id="id ?? name"
          :name="name"
          ref="target"
          v-model="value"
          v-maska
          :autocomplete="autocomplete"
          :disabled="isInputDisabled"
          :data-maska="maskaFormat"
          :class="{
            'input-text__textfield w-full !appearance-none bg-white py-2 pl-4 pr-3 !outline-none placeholder:sr-only disabled:text-black-40': true,
            'error-border__textfield': !isValid,
            'text-font-bold cursor-pointer pr-4 caret-transparent': isDropdown,
            '[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none':
              type === 'number'
          }"
          type="text"
          @focusin="handleFocusIn"
          @focusout="handleFocusOut"
        />
      </template>
      <template #label>
        <label
          :for="name"
          class="
            label__textfield
            z-[2]
            pointer-events-none
            absolute
            left-4
            top-1/2
            flex
            -translate-y-1/2
            gap-1
            transition-all
          "
          :class="{
            ...labelClass,
            'bg-white': inlineLabel || !isInputDisabled,
            'bg-grey-main': isInputDisabled,
            '!text-negative-main': !isValid,
            'top-5 cursor-pointer select-none': isDropdown,
            'font-bold': isLabelTextBold
          }"
          @click="focusInput"
        >
          <span v-if="customDropdownLabel" class="bg-white font-normal">
            {{ customDropdownLabel }}
          </span>
          {{ label }} <b v-if="inlineLabel" class="font-normal">{{ value }}</b>
          <!-- <IconsMandatory v-if="isRequired" /> -->
          <span v-if="!isRequired && showRequiredness">{{
            $t("general.optionalField")
          }}</span>
          <p v-if="showAsterisk && isRequired">*</p>
        </label>
      </template>
      <template #suffixIcon="{ state }">
        <!-- ALTERNATIVE ICON TEMPLATE for password and email fields
            It gives them relative positioning rather than the default absolute
        This avoids overlapping between browser password managers and formkit's suffixicons -->
        <div
          v-if="suffixIconsNotAbsolute"
          class="
            alternative-suffix-icons-template
            relative
            flex
            items-center
            self-center
            justify-self-end
            rounded-lg
            bg-transparent
          "
        >
          <IconsCheckmark
            v-if="
              typeof showCheckmark === 'boolean'
                ? showCheckmark
                : !disabled && !!value && state.complete && state.rules
            "
            class="order-2 mr-3 h-6 w-6 text-green-500"
          />
          <div
            v-if="!disabled && showPassword"
            class="
              order-1
              mr-1
              cursor-pointer
              select-none
              rounded-r-lg
              p-2
              text-green-main
            "
            @click="togglePassword"
          >
            <IconsShowPasswordNoText
              v-if="value && showPassword && !isPasswordHidden"
            />
            <IconsHidePasswordNoText v-if="value && isPasswordHidden" />
          </div>
          <IconsEdit
            v-if="!showPassword && isInputDisabled"
            class="order-2 mr-3 h-6 w-6 text-green-500"
            @click="handleEdit"
          />
        </div>
        <!-- DEFAULT ICON TEMPLATE-->
        <div class="default-suffix-icon-template" v-else>
          <IconsChevronDown
            v-if="isDropdown && !isCalendarSelector"
            :class="{ hidden: isFocused, 'cursor-pointer': isDropdown }"
            class="
              pointer-events-none
              absolute
              right-1
              top-1/2
              h-6
              w-6
              -translate-y-1/2
            "
          />
          <IconsCalendar
            @click="handleFocusIn"
            v-else-if="isCalendarSelector && !hasCalendarIconHidden"
            class="
              absolute
              right-3
              top-1/2
              -z-0
              h-6
              w-6
              -translate-y-1/2
              cursor-pointer
            "
          />
          <IconsCheckmark
            v-if="
              typeof showCheckmark === 'boolean'
                ? showCheckmark
                : !disabled && !!value && state.complete && state.rules
            "
            class="
              absolute
              right-3
              top-1/2
              h-6
              w-6
              -translate-y-1/2
              text-green-500
            "
          />
          <IconsQuestionMarkCircle
            v-if="showQuestionMark"
            ref="questionMark"
            class="
              text-gray-500
              absolute
              right-1
              top-1/2
              h-6
              w-6
              -translate-y-1/2
            "
          />
          <UITooltip
            v-if="isTooltipVisible"
            class="c_lg:top-12 absolute top-10"
          />
          <div
            v-if="!disabled"
            class="
              right-[1px]
              absolute
              top-0
              cursor-pointer
              rounded-r-lg
              text-green-main
            "
          >
            <IconsShowPassword
              v-if="value && showPassword && !isPasswordHidden"
              @click="togglePassword"
            />
            <IconsHidePassword
              v-if="value && isPasswordHidden"
              @click="togglePassword"
            />
          </div>
          <slot name="suffixIcon" />
        </div>
      </template>
      <template #help>
        <div
          class="
            help-text__textfield
            mouse
            absolute
            left-0
            right-0
            flex
            justify-between
            pt-1
            text-black-80
          "
          :class="!!label || !!customDropdownLabel ? 'top-[46px]' : 'top-10'"
        >
          <p
            v-if="!isValid || !isHelpTextHidden"
            class="ml-4"
            :class="{ '!text-negative-main': !isValid }"
          >
            {{ helpText }}
          </p>
          <p v-if="maxChar && !isCountCharHidden" class="mr-4">
            {{ countChars }}
          </p>
        </div>
      </template>
    </FormKit>
</FormKitLazyProvider>

  </div>
</template>

<style lang="scss">

input:-webkit-autofill {
  animation-name: onAutoFillStart;
  transition: background-color 50000s ease-in-out 0s;
}

@keyframes onAutoFillStart {
  from {
    /**/
  }
  to {
    /**/
  }
}

</style>
